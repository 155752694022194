<template>
    <div class="box">
        <div class="jbxx">
            <div class="jbtop">
                <div class="icon"></div>
                <div class="tit">代理商分润抽成</div>
            </div>
            <el-form ref="form" :model="form" label-width="200px">
              <el-row>
                <el-col :span="8" style="display: flex;">
                    <el-form-item label="区域代理商">
                      <el-input v-model="form.name"><template slot="append">%</template></el-input>
                    </el-form-item> 
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                    <el-form-item label="区域代理商">
                      <el-input v-model="form.name"><template slot="append">%</template></el-input>
                   </el-form-item>  
                </el-col>
                <el-col :span="8">
                    <el-form-item label="区域个人代理商">
                      <el-input v-model="form.name"><template slot="append">%</template></el-input>
                   </el-form-item>  
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" style="display: flex;">
                    <el-form-item label="平台个人代理商">
                      <el-input v-model="form.name"><template slot="append">%</template></el-input>
                    </el-form-item> 
                </el-col>
              </el-row>
            </el-form>
            <div class="butt" style="text-align: center;">
                <!-- <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button> -->
                <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
 import { quillEditor } from 'vue-quill-editor'
 import 'quill/dist/quill.core.css'
 import 'quill/dist/quill.snow.css'
 import 'quill/dist/quill.bubble.css'
 export default {
  components: {   //界面组件引用
    quillEditor
  },
  data() {
    return {
        form: {
          name: '',
          
        },
        tableData:[
            {name:'wdz'}
        ],
        showDialog:false

    };
  },
  mounted() {
   
  },
  methods: {
    handleSuccess(response, file, fileList){
      console.log( response.data.url);
    },
    onSubmit(){
        // this.$router.push('/staff')
    },
    add(){
        this.showDialog = true
    }
  }
};
  </script>
  
  <style lang="less" scoped>
  .box {
    background-color: #fff;
    padding-top: 20px;
    .jbxx {
        .jbtop {
            width: 1559px;
            height: 56px;
            background-color: #eef1f6;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-left: 10px;
            margin-bottom: 40px;
            .icon {
                width: 6px;
                height: 19px;
                border-radius: 10px;
                background-color: #f77f1f;
                margin-right: 10px;
            }
            .tit {
                font-weight: 700;
            }
            
            
        }
        .edi {
                margin-left: 20px;
                height: 450px;
                display: flex;
                .editor {
                width: 817px;
                height: 372px;
                margin-top: 20px;
               }
            }
    }
    .butt {
        // display: flex;
        // justify-content: space-between;
        padding: 50px 0px;
    }
    .tab {
        padding: 20px;
    }
    .dia {
      .diafour {
            display: flex;
            justify-content: space-between;
            padding: 20px 230px;
        }
    }
  }
  </style>
  